<template>
  <div class="news">
    <Normaltop
      :url="qiniuUrl"
      title="认证体系"
      en="Certification System"
    ></Normaltop>
    <div class="news-cont">
      <normalcert :url="url" :info="info"></normalcert>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Normalcert from '../components/nomalcert'
import Normaltop from '../components/normaltop'
export default {
  name: 'Certificate',
  components: { Normalcert, Normaltop },
  data() {
    return {
      news: [],
      qiniuUrl: this.$qiniuUrl + 'rz/bg.png',
      url: [
        require('../assets/images/rz/img1.png'),
        require('../assets/images/rz/img2.png'),
      ],
      info: {
        title: '网培师认证介绍',
        tit: '网培师认证要求',
        desc: `1.坚持社会主义核心价值观，充分了解国家在互联网管理及互联网教学培训管理方面的相关政策和法规。<br/>
2.有较强的沟通能力，具备基本的课堂教学或教学辅导素养，对自然科学及社会人文学科知识有基本的了解。<br/>
3.具有教育学、心理学和教学法的基础知识，具备所教领域的专业知识，能够独立依据教学大纲及教材，正确传授知识和技能，教学效果良好。<br/>
4.熟练掌握教学课件的制作和演示，并能在网络教学中熟练应用。有一定的在线课程制作及发布经验。<br/>
5.了解和掌握常用的在线教育及数字化教学的工具系统的使用方法。<br/>
6.完成并通过网培师相应阶段的评测。
        `,
        req:
          '<p>随着在线经济的蓬勃发展，特别是后疫情时代，在线教育的需求得到催化和爆发，急需建立相关从业人员的行业从业标准和管理规范，提升网络在线教育从业人员的专业水平执业能力。网培师能力评测标准和体系的建设，得到了国内主流教育培训机构的广泛响应和参与。项目采用网班教育的《网培师教育培训及评测标准》体系，面向国内的在线教育培训工作者提供技能培训、评测认证、上岗就业等系列服务。</p>',
        gn: '认证网培师的能力概述',
        cont: `通过网培师评测和认证的教培行业从业人员，应具备以下能力：<br/>
1. 了解并掌握与在线直播相关的法律法规，包括但不限于《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《信息网络传播权保护条例》以及《中华人民共和国网络安全法》；
<br/>2. 熟练掌握在线课程教学工具和方法，如PowerPoint, WPS, 会声会影等，能够胜任中大型在线课堂的教学工作，教学效果较好，同时具有良好的语言表达能力、幽默感，可以实现线上和学员的互动，有比较宽广的知识面，能够对讲授的内容都进行引申讲解
<br/>3. 具有教育学、心理学和教学法的基础知识，具备所教领域的专业知识，能够独立掌握所教课程的教学大纲、教材，正确传授知识和技能，教学效果较好，能够坚持大量备课、讲课，不断反思提升自己的教学内容
<br/>4. 态度认真负责，富有同理心，具备良好的服务意识，能够耐心解答学生问题，逻辑思维能力强，系统性思维和优秀的语言表达能力优秀，适应在线课堂场景。`,
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.news = [
        {
          title: '新闻标题',
          desc: '新闻描述',
          created: '2020-03-30 12:00:00',
          type: 1,
          id: 1,
        },
        {
          title: '公告标题',
          desc: '新闻描述',
          created: '2020-03-30 12:00:00',
          type: 2,
          id: 2,
        },
        {
          title: '新闻标题',
          desc: '新闻描述',
          created: '2020-03-30 12:00:00',
          type: 1,
          id: 3,
        },
      ]
    },
    toNewsDesc(id) {
      this.$router.push({ name: 'NewsDesc', params: { id: id } })
    },
  },
}
</script>
<style lang="less" scoped>
.news {
  font-weight: normal;
  font-size: 16px;
  img {
    width: 100%;
  }
  .news-cont {
    width: 60%;
    min-width: 1100px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding: 70px 0;
    .part {
      display: flex;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #e2e2e2;
      .date-box {
        width: 100px;
        height: 100px;
        color: #797979;
        background: #e9ebf0;
        text-align: center;
        h1 {
          font-weight: normal;
          font-size: 48px;
        }
      }
      .news-title {
        color: #000000;
        font-weight: bold;
        font-size: 18px;
        padding-left: 10px;
        .red-text {
          color: #960f23;
        }
        .yellow-text {
          color: #c9850e;
        }
      }
      .news-desc {
        padding: 10px 20px;
        color: #333;
        line-height: 1.5;
      }
    }
  }
}
</style>
